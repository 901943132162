
import { ref, onMounted, defineComponent } from 'vue'
import { useAuth } from '@/store/index'
import { useRouter } from 'vue-router'
import {
  NSpin,
  NSpace,
  NInput,
  NButton,
  NModal,
  NForm,
  NFormItem,
  FormItemRule,
  FormInst,
  useMessage,
  NAlert,
} from 'naive-ui'
import { API, APIUnsecure } from '@/api/api'
export default defineComponent({
  components: {
    NSpin,
    NSpace,
    NInput,
    NButton,
    NAlert,
    NModal,
    NForm,
    NFormItem,
  },
  setup() {
    const formRef = ref<FormInst | null>(null)
    const state = useAuth()
    const message = useMessage()
    const router = useRouter()
    const showModalOtpStore = ref(false)
    const showModalOtpCode = ref(false)

    const form = ref({
      email: '',
      password: '',
    })

    async function login() {
      formRef.value?.validate(async (errors) => {
        if (!errors) {
          try {
            const response = await APIUnsecure().post(
              'auth/signin/otp',
              form.value
            )
            state.qrCode = response.data.qr
            state.otpToken = response.data.key
            state.otpCode = ''
            showModalOtpStore.value = true
          } catch (error) {
            message.error('Email ou senha inválidos!')
          }
        } else {
          console.log(errors)
          message.error('Preecha as informações corretamente')
        }
      })
    }

    async function setOtpCode() {
      try {
        const response = await API().post('auth/otp/validate', {
          code: state.otpCode,
          key: state.otpToken,
        })
        console.log(response.data)
        localStorage.setItem('user', JSON.stringify(response.data))
        message.success('Login realizado com sucesso')
        await new Promise((resolve) => setTimeout(resolve, 1000))
        router.push('/home')
      } catch (error) {
        message.error('Código inválido')
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          login()
        }
      })
    })

    return {
      formRef,
      setOtpCode,
      form,
      state,
      login,
      showModalOtpStore,
      rules: {
        form: {
          email: {
            required: true,
            trigger: 'input',
            validator(rule: FormItemRule, value: string) {
              if (!value || value.length == 0) {
                return new Error('Digite um email, não pode ser vazio')
              } else if (
                !/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i.test(value)
              ) {
                return new Error('Digite um email válido')
              }
              return true
            },
          },
          password: {
            required: true,
            message: 'Digite uma senha válida',
            trigger: ['input', 'blur'],
          },
        },
      },
    }
  },
})
